<template>
    <div>
        <b-container>
            <section class="faq">
                <h2 class="main-title">FAQ</h2>
                <div role="tablist" class="mt-4">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-1>What is Branding Pavilion and what can I find there?</button>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">Branding Pavilion is the global digital marketing platform for companies involved in the advertising/digital industry. We provide online directory of companies, events, job offers and interviews from the market.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-2>Can I add my company/event/job offer?</button>
                        </b-card-header>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">Yes, you can add one company and an unlimited number of events/job offers.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-3>What is the process of adding new company/event/job offer?</button>
                        </b-card-header>
                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">First you need to create a new account, then you can add your company/event/job offer from panel by filling the correct form. After that, your company/event/job offer will get status 'pending' which means that we have to verify content you want to publish, this process can take up to 24 hours. If the verification of content succeeds your company/event/job offer will appear in public directory.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-4>Do I have to pay any fee in order to add my company/event/job offer?</button>
                        </b-card-header>
                        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">No, Branding Pavilion is a free platform but we provide  optional sponsorship opportunities that can help stand out from the competition. You can find our sponsorship offer <router-link to="/sponsorship" class="form-link form-link--medium">here</router-link>.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-5>What types of sponsorship are available?</button>
                        </b-card-header>
                        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">We offer three types of paid sponsorship: STANDARD, PREMIUM and ULTIMATE. You can find out more about sponsorship offer <router-link to="/sponsorship" class="form-link form-link--medium">here</router-link>.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-6>Which payment methods do you support?</button>
                        </b-card-header>
                        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">We use third party service provider to complete payment. We support credit card, debit card and PayPal as a payment method.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-7>How does the payment process look like?</button>
                        </b-card-header>
                        <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">To complete payment process you have to be signed in to your account. Than from our sponsorship page(located  <router-link to="/sponsorship" class="form-link form-link--medium">here</router-link>) you can select plan. After that follow the instructions provided by payment gate. If payment completes successfully we will upgrade your account and send you an invoice for your payment. Notice that this process can take up to 48 hours.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-8>What billing model do you use?</button>
                        </b-card-header>
                        <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">We use subscription billing model, paid yearly and charged automatically.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-9>How can I cancel my subscription?</button>
                        </b-card-header>
                        <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">You can cancel your current subscription from your profile page in 'Subscription' panel.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                            <button class="faq__button" block v-b-toggle.accordion-10>How can I change my subscription plan?</button>
                        </b-card-header>
                        <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text class="faq__text">The best way to change subscription plan is to cancel first your current plan in 'Subscription' panel and then select a new plan from 'Sponsorship' page located <router-link to="/sponsorship" class="form-link form-link--medium">here</router-link>. If you need more information don't hesitate to contact us: <a href="mailto:contactbrandingpavilion@gmail.com" class="form-link form-link--medium">contactbrandingpavilion@gmail.com</a>.</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>
